<template>
  <div
    class="card-cart-item"
    :class="{
      'card-cart-item--not-available': !available,
      'card-cart-item--pmd': md,
    }"
  >
    <span
      v-if="!hideClose"
      class="card-cart-item__close"
      @click="$emit('close')"
      >X</span
    >
    <div class="card-cart-item__name">
      {{ name }}
      <div v-if="detailDeal != null" class="deal-deail">
        {{
          `${detailDeal.name} (${detailDeal.qty_item} + ${detailDeal.qty_bonus})`
        }}
      </div>
    </div>
    <slot name="side-content">
      <div class="card-cart-item__price">
        <v-tooltip left v-if="!available || !availableGender">
          <template v-slot:activator="{ on, attrs }">
            <v-icon dense v-bind="attrs" v-on="on"
              >mdi-help-circle-outline</v-icon
            >
          </template>

          <span v-if="!available">{{
            $t("page.cart.this_test_not_avail")
          }}</span>
          <span v-if="!availableGender">{{
            $t("page.cart.this_test_not_avail_gender")
          }}</span>
        </v-tooltip>

        <p class="real-price mb-0" v-if="realPrice != ''">
          {{ realPrice }}
        </p>
        <p class="mb-0">
          {{ price }}
        </p>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    realPrice: {
      type: String,
      default: "",
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    available: {
      type: Boolean,
      default: true,
    },
    availableGender: {
      type: Boolean,
      default: true,
    },
    md: {
      type: Boolean,
      default: false,
    },
    detailDeal: {
      type: Object,
      default: () => null,
    },
  },
  // created() {
  //   console.log("test", this.detailDeal);
  // },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.card-cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 12px;
  padding-top: 12px;
  font-style: normal;
  line-height: 20px;
  position: relative;
  &--not-available {
    .card-cart-item__name {
      color: red;
    }
  }
  &--pmd {
    padding-top: 14px;
    padding-bottom: 10px;
  }
  &__name {
    font-size: 12px;
    font-weight: 600;
    color: $main_1;
    width: 100%;
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 12px;
    //     line-height: 20px;
    .deal-deail {
      color: $dark_blue;
    }
  }
  &__price {
    white-space: nowrap;
    width: fit-content;
    padding-left: 8px;
    padding-top: 8px;
    font-size: 12px;
    color: $main_1;
    font-weight: 600;
    text-align: right;
    //     font-style: normal;
    //     font-weight: 600;
    //     font-size: 12px;
    //     line-height: 20px;
    .real-price {
      text-decoration: line-through;
      color: $gray_3;
    }
  }
  &__close {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
}
</style>
