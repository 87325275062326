var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"price",class:{
    'text-right': _vm.position == 'right',
    'text-left': _vm.position == 'left',
    'text-center': _vm.position == 'center',
    'price--discount': _vm.discount,
    'price--gray': _vm.color == 'gray',
    'price--main': _vm.color == 'main',
    'price--bold': _vm.bold,
    'price--extra-bold': _vm.extraBold,
  }},[_vm._v(" "+_vm._s(_vm.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }