<template>
  <p
    class="price"
    :class="{
      'text-right': position == 'right',
      'text-left': position == 'left',
      'text-center': position == 'center',
      'price--discount': discount,
      'price--gray': color == 'gray',
      'price--main': color == 'main',
      'price--bold': bold,
      'price--extra-bold': extraBold,
    }"
  >
    {{ text }}
  </p>
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      default: "left",
    },
    discount: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    bold: {
      type: Boolean,
      default: false,
    },
    extraBold: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/color.scss";
.price {
  margin: 0;
  color: $dark_blue_3;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  &--discount {
    color: $gray_10;
    text-decoration: line-through;
  }
  &--gray {
    color: $dark;
  }
  &--main{
    color: $main_1;
  }
  &--bold {
    font-weight: 600;
  }
  &--extra-bold {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
