<template>
  <v-dialog v-model="dialog" width="700px">
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" v-on="on">
        <v-icon class="tnc"> mdi-information-outline </v-icon>
      </a>
    </template>
    <v-card>
      <v-card-title class="text-h5 tnc-header">
        {{ $t("page.cart.tnc") }}
      </v-card-title>
      <v-card-text class="content-dialog">
        {{ text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          {{ $t("ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    text: {
      type: String,
      default: "-",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.title-dialog {
  font-size: 12px;
  text-transform: uppercase;
  color: #00a4b3;
  font-weight: bold;
}
.content-dialog {
  text-align: justify;
  font-size: 12px;
  line-height: 20px;
  padding: 20px 20px 20px 20px !important;
  color: #1f3c87 !important;
}
.tnc-header {
  background-color: #00a4b3;
  justify-content: center;
  color: white;
}
.mt-title-general {
  margin-top: -5px;
}
.mt-title {
  margin-top: -30px;
}
.tnc {
  color: $main_2;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
}
</style>
