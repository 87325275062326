<template>
  <div
    class="voucher-card"
    :class="{ 'voucher-card--active': active }"
    @click="$emit('toggleVoucher')"
  >
    <v-row no-gutters>
      <v-col cols="3">
        <div class="voucher-card__box voucher-card__border">
          <icons icon="virtu" />
        </div>
      </v-col>
      <v-col cols="9">
        <div class="voucher-card__box">
          <heading-price
            :text="discount"
            color="main"
            position="left"
            bold
            class="mb-1"
          />
          <p class="desc mb-0">{{ desc }}</p>
          <p class="code mb-0">
            Promo Code:
            <span>
              {{ code }}
              <v-icon class="icon-copy">mdi-content-copy</v-icon>
            </span>
          </p>
          <p class="valid">{{ valid }} : {{ dateValid }}</p>
          <tnc :text="tnc" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeadingPrice from "@/components/Heading/HeadingPrice.vue";
import Icons from "@/components/elements/Icons.vue";
import Tnc from "@/components/v2/tnc/voucher.vue";
export default {
  components: {
    HeadingPrice,
    Icons,
    Tnc,
  },
  props: {
    discount: {
      type: String,
      default: "-",
    },
    code: {
      type: String,
      default: "-",
    },
    desc: {
      type: String,
      default: "-",
    },
    valid: {
      type: String,
      default: "-",
    },
    dateValid: {
      type: String,
      default: "-",
    },
    active: {
      type: Boolean,
      default: false,
    },
    tnc: {
      type: String,
      default: "-",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.voucher-card {
  display: flex;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 18px;
  position: relative;
  background: #fbfbfb;
  overflow: hidden;
  border-radius: 8px !important;
  cursor: pointer;
  &--active {
    background: #eeeded;
  }
  &__box {
    flex-direction: column;
    padding: 0 12px;
    display: flex;
    height: 100%;
    .valid {
      font-weight: 500;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #ff0000;
      margin-bottom: 0;
    }
    .desc {
      color: #000;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }
    .code {
      color: #000;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      span {
        color: $main_1;
        text-transform: uppercase;
        font-weight: 600;
      }
      .icon-copy {
        color: $main_1;
        font-size: 12px;
      }
    }
  }
  &__border {
    justify-content: center;
    align-items: center;
    border-right: 2px dashed rgba(102, 102, 102, 0.2);
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 24px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    width: 28px;
    height: 26px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.25);
  }
}
</style>
